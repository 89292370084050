/* eslint-disable max-len */
import * as yup from 'yup';
// import { PHONE_REGEX } from '@src/constants/regex';
import dayjs from 'dayjs';
import { EIN_REGEX, SSN_REGEX } from '@src/constants/regex';

export const FORM_CURRENT_STEP = 'current';
export const FORM_UPCOMING_STEP = 'upcoming';
export const FORM_COMPLETE_STEP = 'complete';

export const TITAN_BANK = 'titan';
export const KEYSTONE_BANK = 'keystone';

const personalDetailsBasicSchema = {
    title: yup.string(),
    first_name: yup.string().required('First name is required'),
    middle_name: yup.string(),
    last_name: yup.string().required('Last name is required'),
    DOB: yup.string()
        .required('Date of birth is required')
        .test('maxDate', 'Date Must be in the past', (date) => +new Date(`${date}T00:00:00`) <= +new Date())
        .test('minDate', 'Invalid date', (date) => new Date(`${date}T00:00:00`) >= new Date('1900-01-01T00:00:00')),
    address01: yup.string().required('Address is required'),
    address02: yup.string(),
    city: yup.string().required('City is required'),
    postal_code: yup.string().required('Postal Code is required'),
    state: yup.string().required('State is required'),
    mailing_equals_billing: yup.boolean(),
    mailing_address01: yup.string().when('mailing_equals_billing', {
        is: true,
        then: yup.string().required('Mailing Street is required'),
    }),

    mailing_address02: yup.string(),
    mailing_city: yup.string()
        .when('mailing_equals_billing', {
            is: true,
            then: yup.string().required('Mailing City is required'),
        }),
    mailing_postal_code: yup.string()
        .when('mailing_equals_billing', {
            is: true,
            then: yup.string().required('Mailing Postal Code is required'),
        }),
    mailing_state: yup.string()
        .when('mailing_equals_billing', {
            is: true,
            then: yup.string().required('Mailing State is required'),
        }),
    email: yup
        .string()
        .email('Please type valid email address')
        .required('Email is required'),
    phone: yup
        .string()
        .required('Phone is required'),
    // .matches(PHONE_REGEX, 'Phone number is not valid'),
    occupation: yup.string().required('Occupation is required'),
    control_group: yup.bool(),
    gender: yup.string()
        .required('Gender is required'),
    ssn_number: yup.string()
        .matches(SSN_REGEX[0], 'Should be valid SSN')
        .matches(SSN_REGEX[1], 'Should be valid SSN')
        .required(),
};

export const personalDetailsSettingsSchema = yup.object().shape({
    ...personalDetailsBasicSchema,
    spouse_first_name: yup.string().when(['spouse_last_name', 'spouse_email'], {
        is: (spouseLastName, spouseEmail) => Boolean(spouseLastName || spouseEmail),
        then: (schema) => schema.required('Spouse\'s First Name is required'),
    }),
    spouse_last_name: yup.string().when(['spouse_first_name', 'spouse_email'], {
        is: (spouseFirstName, spouseEmail) => Boolean(spouseFirstName || spouseEmail),
        then: (schema) => schema.required('Spouse\'s Last Name is required'),
    }),
    spouse_email: yup.string().email('Please type valid email address').when(['spouse_first_name', 'spouse_last_name'], {
        is: (spouseFirstName, spouseLastName) => Boolean(spouseFirstName || spouseLastName),
        then: (schema) => schema.required('Spouse\'s email is required'),
    }),
}, [
    ['spouse_last_name', 'spouse_email'],
    ['spouse_first_name', 'spouse_email'],
    ['spouse_first_name', 'spouse_last_name'],
]);

export const personalDetailsSchema = yup.object().shape({
    ...personalDetailsBasicSchema,
    married: yup.bool(),
    spouse_first_name: yup.string().when('married', {
        is: true,
        then: (schema) => schema.required('Spouse\'s First Name is required'),
    }),
    spouse_last_name: yup.string().when('married', {
        is: true,
        then: (schema) => schema.required('Spouse\'s Last Name is required'),
    }),
    spouse_email: yup.string().email('Please type valid email address').when('married', {
        is: true,
        then: (schema) => schema.required('Spouse\'s email is required'),
    }),
});

export const bankDetailsSchema = yup.object().shape({
    bank_account_monthly_activity_domestic: yup
        .string()
        .required('Account Monthly Domestic Activity is required'),
    bank_account_monthly_activity_international: yup
        .string()
        .required('Account Monthly International Activity is required'),
    bank_account_monthly_wire_activity: yup
        .string()
        .required('Account Monthly Wire Activity is required'),
    bank_account_monthly_ach_activity: yup
        .string()
        .required('Account Monthly ACH Activity is required'),
    bank_assets_type: yup.string().required('Assets Type is required'),
    bank_assets_type_other: yup.string(),
    bank_income: yup.string().required('Income is required'),
    bank_source_of_funds: yup.string().required('Source of Funds is required'),
    bank_source_of_funds_other: yup.string(),
    bank_terms_agree: yup
        .boolean()
        .required('The terms and conditions must be accepted.')
        .oneOf([true], 'The terms and conditions must be accepted.'),
});

export const bankApplicationSchema = yup.object().shape({
    bank_account_monthly_activity_domestic: yup
        .string()
        .required('Account Monthly Activity Domestic is required'),
    bank_account_monthly_activity_international: yup
        .string()
        .required('Account Monthly Activity International is required'),
    bank_assets_type: yup.string().required('Assets Type is required'),
    bank_assets_type_other: yup.string(),
    bank_income: yup.string().required('Income is required'),
    bank_source_of_funds: yup.string().required('Source of Funds is required'),
    bank_source_of_funds_other: yup.string(),

});

export const employerDetailsSchema = yup.object().shape({
    employer_ein_number: yup.string()
        .matches(EIN_REGEX, 'Should be valid EIN')
        .required(),
    employer_name: yup.string().required('First name is required'),
    employer_address01: yup.string().required('Address is required'),
    employer_address02: yup.string(),
    employer_city: yup.string().required('City is required'),
    employer_postal_code: yup.string().required('Postal code is required'),
    employer_state: yup.string().required('State is required'),
    employer_email: yup.string().email('Please type valid email address'),
    employer_phone: yup
        .string(),
    // .matches(PHONE_REGEX, 'Phone number is not valid'),
    employer_website: yup.string(),
    employer_mailing_equals_billing: yup.boolean(),
    employer_mailing_address01: yup
        .string()
        .when('employer_mailing_equals_billing', {
            is: true,
            then: (schema) => schema.required('Mailing address is required'),
        }),
    employer_mailing_address02: yup.string(),
    employer_mailing_city: yup
        .string()
        .when('employer_mailing_equals_billing', {
            is: true,
            then: (schema) => schema.required('Mailing city is required'),
        }),
    employer_mailing_postal_code: yup
        .string()
        .when('employer_mailing_equals_billing', {
            is: true,
            then: (schema) => schema.required('Mailing Postal code is required'),
        }),
    employer_mailing_state: yup
        .string()
        .when('employer_mailing_equals_billing', {
            is: true,
            then: (schema) => schema.required('Mailing state is required'),
        }),
    entity_type: yup.string().required('Entity type is required'),
    employer_incorp_state: yup
        .string()
        .required('Incorporation State is required'),
    employer_incorp_date: yup
        .date()
        .max(new Date(), 'Must be in the past')
        .min(dayjs().subtract(100, 'years').toDate(), 'Invalid date')
        .typeError('Incorporation date is required')
        .required('Incorporation date is required'),
    employer_fiscal_year_end: yup
        .string()
        .required('Tax year end date is required'),
    employer_fiscal_year_end_date: yup
        .string()
        .nullable()
        .when('employer_fiscal_year_end', {
            is: 'fiscalBased',
            then: (schema) => schema.required('Employer Fiscal Year End Date is required'),
        }),
    employer_naics_code: yup
        .string()
        .required('Industry code is required'),
});

export const identityDetailsSchema = yup.object().shape({
    id_type: yup.string().required('ID Type is required'),
    id_state: yup.string().required('State Issued name is required'),
    id_number: yup.string().required('ID Number is required'),
    id_expiry_date: yup
        .date()
        .max(dayjs().add(100, 'years').toDate(), 'Invalid date')
        .min(dayjs(), 'Expiration date can not be in the past')
        .typeError('Expiration date is required')
        .required('Expiration date is required'),
});

export const planDetailsSchema = yup.object().shape({
    plan_loan: yup.boolean(),
    plan_roth: yup.boolean(),
    plan_roth_roll: yup.boolean(),
    plan_name: yup.string().required('Plan Name is required'),
    plan_establish_date: yup
        .date()
        .max(new Date(), 'Must be in the past')
        .min(dayjs().subtract(100, 'years').toDate(), 'Invalid date')
        .typeError('Tax Year Plan is Established is required')
        .required('Tax Year Plan is Established is required'),
    loan_interest_rate: yup
        .number()
        .min(1, 'Minimum loan interest rate is 1%')
        .max(100, 'Maximum loan interest rate is 100%')
        .typeError('Loan interest rate must be a number'),
    loan_interest_rate_type: yup.string(),
    plan_signer_first_name: yup.string().required('First Name is required'),
    plan_signer_last_name: yup.string().required('Last Name is required'),
    plan_signer_title: yup.string().required('Title is required'),
    isSelectedSignature: yup.boolean().oneOf([true, false]),
    plan_signature: yup.string().when('isSelectedSignature', {
        is: false,
        then: (schema) => schema
            .required('Signature is required')
            .matches(
                /^data:image\/(?:gif|png|jpeg|bmp|webp|svg\+xml)(?:;charset=utf-8)?;base64,(?:[A-Za-z0-9]|[+/])+={0,2}/,
                'Signature must be png',
            ),
    }),
    plan_sign_date: yup
        .date()
        .max(dayjs().add(1, 'day').toDate(), 'Must be in the past')
        .min(dayjs().subtract(100, 'years').toDate(), 'Invalid date')
        .typeError('Signed on Date is required')
        .required('Signed on Date is required'),
});

export const signerDetailsSchema = yup.object().shape({
    participant_signer_first_name: yup.string().required('First Name is required'),
    participant_signer_last_name: yup.string().required('Last Name is required'),
    participant_signer_title: yup.string().required('Title is required'),
    isSelectedSignature: yup.boolean().oneOf([true, false]),
    participant_signature: yup.string().when('isSelectedSignature', {
        is: false,
        then: (schema) => schema
            .required('Signature is required')
            .matches(
                /^data:image\/(?:gif|png|jpeg|bmp|webp|svg\+xml)(?:;charset=utf-8)?;base64,(?:[A-Za-z0-9]|[+/])+={0,2}/,
                'Signature must be png',
            ),
    }),
    participant_sign_date: yup
        .date()
        .max(dayjs().add(1, 'day').toDate(), 'Must be in the past')
        .min(dayjs().subtract(100, 'years').toDate(), 'Invalid date')
        .typeError('Signed on Date is required')
        .required('Signed on Date is required'),
});

export const planSettingsSchema = yup.object().shape({
    plan_ein_number: yup.string()
        .matches(EIN_REGEX, 'Should be valid EIN')
        .required(),
    plan_name: yup.string().required('Plan Name is required'),
    plan_establish_date: yup
        .date()
        .max(new Date(), 'Must be in the past')
        .min(dayjs().subtract(100, 'years').toDate(), 'Invalid date')
        .typeError('Tax Year Plan is Established is required')
        .required('Tax Year Plan is Established is required'),
});

const ALLOWED_CHARACTERS = /^[0-9a-zA-Z\s]*$/;
export const DIGITS_ONLY = /^[0-9]*$/;

export const naicsSearchSchema = yup.object().shape({
    search_value: yup.string()
        .test(
            'min',
            'Search value must contain at least 2 non-whitespace characters',
            (value) => value.replaceAll(' ', '').length >= 2,
        )
        .test(
            'allowedCharacters',
            'Only letters digits and spaces are allowed',
            (value) => ALLOWED_CHARACTERS.test(value),
        )
        .test(
            'maxCodeLength',
            'Code length should not be longer then 6 digits',
            (value) => !(DIGITS_ONLY.test(value) && value.length > 6),
        ),
});

export const controlledEmployerSchema = yup.object().shape({
    employer_ein: yup.string()
        .matches(EIN_REGEX, 'Should be valid EIN')
        .required(),
    employer_name: yup.string().required('First name is required'),
    employer_address01: yup.string().required('Address is required'),
    employer_address02: yup.string(),
    employer_city: yup.string().required('City is required'),
    employer_postal_code: yup.string().required('Postal code is required'),
    employer_state: yup.string().required('State is required'),
    employer_email: yup.string().email('Please type valid email address'),
    employer_phone: yup
        .string(),
    // .matches(PHONE_REGEX, 'Phone number is not valid'),
    employer_website: yup.string(),
});
